export default defineNuxtRouteMiddleware(async (to) => {
  const getRouteBaseName = useRouteBaseName();
  const localePath = useLocalePath();
  const searchService = useSearchService();

  if (!to.params.identifier) {
    return;
  }

  if ((to.params.identifier as string).match(/^\d+$/)) {
    try {
      const response = await searchService.getSlugByCampingId(to.params.identifier as string);
      return navigateTo(
        localePath({
          name: getRouteBaseName(to) || RBN_CAMPSITE_DETAIL,
          params: {
            identifier: response.slug,
          },
        }),
        { redirectCode: 301 },
      );
    } catch (err) {
      const statusCode = err?.response?.statusCode || err?.response?.status || 500;
      throw createError({ statusCode });
    }
  }
});
